import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../pages/404-not-found/404NotFound";
import DisplayBardCode from "../pages/bard-code-page/DisplayBardCode";

const index = () => {
	return (
		<Routes>
			<Route path="/:id" element={<DisplayBardCode />} exact />
			<Route path="/" element={<NotFound />} exact />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};

export default index;
