import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  SALEM_API,
  NODE_API,
  SALEM_USERNAME,
  SALEM_PASSWORD,
  checkResponse,
} from "../../config/config";
import fileDownload from "js-file-download";
import { withLoader } from "../../shared/hoc/withLoader";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";

const getOs = () => {
  const os = ["Windows", "Android", "iOS", "Mac"]; // add your OS values
  return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
};

const DisplayBardCode = ({ setBusy }) => {
  const { id } = useParams();
  const [ticketTokenData, setTicketTokenData] = useState(null);
  const [isSuccessfull, setIsSuccessfull] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const deviceOs = getOs();
  const ref = React.useRef();
  const [triggerClick, setTriggerClick] = useState(false);
  const [isAlreadyTriggered, setIsAlreadTriggered] = useState(false);
  const [appleButton, setAppleButton] = useState(false);
  const [fileUrl, setFileUrl] = useState(null)

  React.useEffect(() => {
    if (isSuccessfull && isLoaded && triggerClick) {
      ref.current.click();
    }
  }, [isSuccessfull, isLoaded, triggerClick]);

  // React.useEffect(() => {
  //   document.getElementById("ticketMaster").addEventListener("load", () => {
  //     setIsLoaded(true);
  //     window.Presence.init();
  //   });
  //   //   return () => document.getElementById('#ticketMaster').remove("load")
  // }, []);

  React.useEffect(() => {
    fetchTransferTicket();
    setIsLoaded(true);
  }, []);

  const fetchTransferTicket = async () => {
    try {
      setBusy(true);

      const bearerResponse = await axios.post(
        `${SALEM_API}/Auth/login
        `,
        {
          username: SALEM_USERNAME,
          password: SALEM_PASSWORD,
        }
      );

      const bearerToken = bearerResponse.data.data.token;

      const res = await axios.get(
        `${SALEM_API}/api/TicketMaster/getseatbarcodewtoken?Token=${id}`,
        {
          headers: { Authorization: `Bearer ${bearerToken}` },
        }
      );

      let { isSuccessfull, message } = checkResponse(res.data);
      if (isSuccessfull) {
        setIsSuccessfull(true);
        setTicketTokenData(message);
      } else {
        setIsSuccessfull(false);
        setTicketTokenData(null);
        setErrorMessage(message);
      }
    } catch (error) {
      setIsSuccessfull(false);
      setTicketTokenData(null);
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  const addToAppleWallet = async (ticket) => {
    try {
      let { pkPassId } = ticket;
      let body = {
        pkPassId,
      };

      setAppleButton(true);
      const appleWalletData = await axios.post(
        `${NODE_API}/getPkpassFile`,
        body,
        {
          responseType: "arraybuffer"
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([appleWalletData.data], {
          type: "application/vnd.apple.pkpass",
        })
      );
      setFileUrl(url)
      // fileDownload(
      //   appleWalletData?.data,
      //   `AppleWallet-${new Date().getTime()}.pkpass`
      // );
    } catch (error) {
      console.error(error);
      alert("Error in downloading pkpass file");
    }
    setAppleButton(false);
  };

  const googleWallet = async (ticket) => {
    let { jwt } = ticket;
    window.open(`https://pay.google.com/gp/v/save/${jwt}`, "__blank");
  };

  const getWindowPresent = (ticket, index) => {
    let data = new window.Presence.SecureEntryView({
      selector: `#token-container-${index + 1}`,
      token: ticket.token,
    });
    return <></>;
  };

  const checkIfItsMapped = (index) => {
    if (index === ticketTokenData.tickets.length - 1) {
      if (!isAlreadyTriggered) {
        setTriggerClick(true);
        setIsAlreadTriggered(true);
      }
    }
  };

  return (
    <>
      {!errorMessage && isSuccessfull && (
        <div
          style={{
            backgroundColor: "#33774c",
            color: "white",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <p style={{ paddingTop: "10px" }}>
            {(ticketTokenData && ticketTokenData?.eventName) || ""}
          </p>
          <p style={{ paddingBottom: "10px", opacity: ".8" }}>
            {ticketTokenData &&
              moment(ticketTokenData?.eventDate).format(
                "ddd MMM DD, YYYY hh:mm A"
              )}{" "}
            - {(ticketTokenData && ticketTokenData?.eventPlace) || ""}
          </p>
          <p style={{ paddingBottom: "10px", opacity: ".8" }}>
            {ticketTokenData &&
              `Total: ${ticketTokenData.tickets.length} tickets`}
          </p>
        </div>
      )}
      <div style={{ margin: "auto" }} className="container">
        <button
          onClick={() => {
            ticketTokenData?.tickets?.map((ticket, index) =>
              getWindowPresent(ticket, index)
            );
          }}
          ref={ref}
          style={{ display: "none" }}
        >
          Render Tickets
        </button>
        <br />
        {/* <Helmet>
          <script
            type="text/javascript"
            src="https://secure-entry.ticketmaster.com/presence-secure-entry.min.js"
            id="ticketMaster"
          ></script>
        </Helmet> */}
        {!isSuccessfull && errorMessage && (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ color: "red" }}>Unable to fetch barcode</div>
            <div style={{ color: "red" }}>{errorMessage}</div>
          </div>
        )}
        {isSuccessfull && isLoaded && (
          <div style={{ width: "100%" }}>
            {ticketTokenData?.barcodeType === "Revolving" && (
              <Carousel
                showStatus={false}
                width="100%"
                showThumbs={false}
                showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      onClick={() => {
                        onClickHandler();
                      }}
                      title={label}
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        top: "calc(90% - 25px)",
                        left: "30%",
                        width: 50,
                        height: 50,
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        fontSize: "30px",
                        border: "none",
                      }}
                    >
                      &lt;
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      onClick={() => {
                        onClickHandler();
                      }}
                      title={label}
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        top: "calc(90% - 25px)",
                        left: "60%",
                        width: 50,
                        height: 50,
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        fontSize: "30px",
                        border: "none",
                      }}
                    >
                      &gt;
                    </button>
                  )
                }
              >
                {ticketTokenData?.tickets?.map((ticket, index) => (
                  <>
                    <div key={ticket.ticketId}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#33774c",
                          color: "white",
                          paddingLeft: "50px",
                          paddingRight: "50px",
                        }}
                      >
                        <p style={{ paddingLeft: "5px" }}>
                          Section <br /> {ticket.ticketId.split(".")[1]}
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          Row <br /> {ticket.ticketId.split(".")[2]}
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          Seat <br /> {ticket.ticketId.split(".")[3]}
                        </p>
                      </div>
                      <div
                        style={{
                          marginTop: "50px",
                        }}
                      >
                        {(deviceOs === "iOS" || deviceOs === "Mac") && (
                          <button
                            onClick={() => addToAppleWallet(ticket)}
                            style={{
                              padding: "10px",
                              backgroundColor: !appleButton && "#010100",
                              color: !appleButton && "white",
                              cursor: "pointer",
                            }}
                            disabled={appleButton}
                          >
                            Add to Apple Wallet
                          </button>
                        )}
                        <button
                          onClick={() => googleWallet(ticket)}
                          style={{
                            padding: "10px",
                            backgroundColor: "#010100",
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          Add to Google Wallet
                        </button>
                        {appleButton && (
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            Note: The file will be downloaded in a minute
                          </p>
                        )}
                       
                      </div>
                      <div style={{marginTop:'10px'}}>
                      {fileUrl && (
                           <a href={fileUrl} download={`AppleWallet-${new Date().getTime()}.pkpass`}>
                            Click Here to download
                          </a>
                        )}
                      </div>
                      <div
                        id={`token-container-${index + 1}`}
                        style={{ maxWidth: "80%", margin: "auto" }}
                      >
                        <div id={`token-id`}></div>
                      </div>
                      {/* {checkIfItsMapped(index)} */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "calc(5%)",
                          paddingLeft: "13px",
                          paddingTop: "calc(15%)",
                        }}
                      >
                        {index + 1} / {ticketTokenData.tickets.length}
                      </div>
                    </div>
                  </>
                ))}
              </Carousel>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default withLoader(DisplayBardCode);
