import React, { useState } from "react";
import { Oval } from "react-loader-spinner";

const Loader = () => (
	<div
		style={{
			position: "fixed",
			width: "100%",
			height: "100%",
			top: "0px",
			left: "0px",
			background: "rgba(255, 255, 255, 0.1)",
			zIndex: "1052",
		}}
	>
		<div
			className="flex justify-center items-center"
			style={{
				position: "absolute",
				left: "50%",
				top: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: "1052",
			}}
		>
			<Oval
				ariaLabel="loading-indicator"
				height={50}
				width={50}
				strokeWidth={5}
				color="#2F80ED"
				secondaryColor="white"
			/>
		</div>
	</div>
);

export const withLoader = (WrappedComponent) => {
	const ComponentWithLoader = (props) => {
		const [loading, setLoading] = useState(false);

		return (
			<>
				{loading && <Loader />}
				<WrappedComponent {...props} setBusy={setLoading} />
			</>
		);
	};

	return ComponentWithLoader;
};
