export const NODE_API = process.env.REACT_APP_NODE_API;
export const SALEM_API = process.env.REACT_APP_SALEM_API;
export const SALEM_USERNAME = process.env.REACT_APP_SALEM_USERNAME;
export const SALEM_PASSWORD = process.env.REACT_APP_SALEM_PASSWORD;

export const checkResponse = (res) => {
    let resType = {
        isSuccessfull: true,
        message: res
    }
    if(res.error){
        resType.isSuccessfull = false
        resType.message = res.error
        return resType
    }

    if(res.message){
        resType.message = res.message
        resType.isSuccessfull = false
        return resType
    }
    return resType

}