import React from "react";

const NotFound = () => {
	return (
		<div
			style={{
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				overflow: "hidden",
			}}
		>
			404 Page Not Found
		</div>
	);
};

export default NotFound;
